.crypto-asset {
  display: inline-block;
  width: 20vw;
  height: 30vh;
  margin-top: 5vh;
  margin-left: 4vw;
  border-radius: 1vh;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  font-size: 2.5vh;
  line-height: 3vh;
  padding-top: 2vh;
  background-color: white;
  position: relative;
  vertical-align: top;
}

img.close-icon {
  position: absolute;
  left: 18vw;
  top: 1vh;
  width: 3vh;
  height: 3vh;
  float: right;
  margin-right: 2vh;
  opacity: 0.4;
  cursor: pointer;
}

img.close-icon:hover {
  opacity: 0.8;
}

.crypto-asset .logo {
  display: inline-block;
  height:4vw;
  width: 4vw;
  margin-left: 8vw;
  margin-bottom: 1.5vh;
  margin-top: 1vh;
  display: block
}

.crypto-asset span.block {
  display: block;
  text-align: center;
}

.crypto-asset span.asset-name {

}

.crypto-asset span.crypto-balance {
  display: inline-block;
  font-size: 2vh;
  color: rgba(0,0,0,0.8);
  margin-top: 2vh;
  cursor: pointer;
}

.crypto-asset input.crypto-balance {
  display: inline-block;
  width: 6vw;
  margin-left: 4vw;
  font-size: 2vh;
  border: none;
  font-weight: 300;
  cursor: pointer;
  padding-right: 1px;
  text-align: right;
  padding-right: 0.5vw;
  border-radius: 1vh;
}

.crypto-asset input.crypto-balance:hover {
  background-color: #5f6a91;
  color: white;
  font-size: 3vh;
}

.crypto-asset input.crypto-balance:focus {
  box-shadow: none;
  outline: none;
  font-size: 3vh;
  background-color: #414e7f;
  color: white;
}


.crypto-asset span.crypto-balance-span {
  display: inline-block;
  width: 10vw;
  font-size: 2vh;
  border: none;
  font-weight: 300;
  cursor: pointer;
  padding-right: 1px;
  text-align: right;
}

.crypto-asset span.crypto-balance-span:hover {
  color: #0a46a8;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}

.crypto-asset span.crypto-balance.value:hover {
  color: #1689D0;
}

.crypto-asset span.local-currency-value {
  font-size: 3vh;
  color: rgba(0,0,0,0.9);
  margin-top: 1.2vh;
}

@media screen and (max-width: 700px) {

  .crypto-asset {
    width: 90vw;
  }

  img.close-icon {
    position: absolute;
    left: 80vw;
    top: 1vh;
    width: 3vh;
    height: 3vh;
    float: right;
    margin-right: 2vh;
    opacity: 0.4;
    cursor: pointer;
  }

  .crypto-asset .logo {
    height:18vw;
    width: 18vw;
    margin-left: 36vw;
    margin-bottom: 3vh;
    margin-top: 1vh;
  }

  .crypto-asset span.block {
    display: block;
    text-align: center;
  }

  .crypto-asset span.asset-name {
    font-size: 3vh;
    font-weight: 500;
  }

  .crypto-asset span.balance-container {
    text-align: center;
    display: block;

  }

  .crypto-asset input.crypto-balance {
    width: 30vw;
    margin-left: 0vw;
    position: relative;
    right: 3vw;
  }

  .crypto-asset:last-child {
    margin-bottom: 22vh;
  }
}
