.new-crypto-asset {
  display: inline-block;
  width: 20vw;
  height: 30vh;
  margin-top: 5vh;
  margin-left: 4vw;
  margin-bottom: 5vh;
  border-radius: 1vh;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  font-size: 2.5vh;
  line-height: 3vh;
  padding-top: 2vh;
  background-color: white;
  position: relative;
}

.new-crypto-asset img.close-icon {
  position: absolute;
  left: 18vw;
  top: 1vh;
  width: 3vh;
  height: 3vh;
  float: right;
  margin-right: 2vh;
  opacity: 0.4;
  cursor: pointer;
}

.new-crypto-asset img.close-icon:hover {
  opacity: 0.8;
}

.new-crypto-asset span.title {
  display: block;
  font-size: 3vh;
  color: black;
  text-align: center;
  height: 4vh;
  margin-top: 2vh;
}

.new-crypto-asset img.search {
  width: 3.5vh;
  height: 3.5vh;
}

.new-crypto-asset input {
  width: 14vw;
  margin-left: 3vw;
  height: 5vh;
  padding-left: 0.2vw;
  padding-right: 0.2vw;
  border-radius: 0.5vh;
  border: solid 1px rgba(0,0,0,0.1);
  margin-top: 3vh;
  font-size: 2vh;
}
.new-crypto-asset input:active {
}

#new-asset-submit {
  width: 3vw;
  height: 3vw;
  color: white;
  background-color: #4FC143;
  margin-left: 8.5vw;
  margin-top: 3vh;
  text-align: center;
  padding-top: 1.2vh;
  cursor: pointer;
  border-radius: 10vh;
}

#new-asset-submit:hover {
  background-color: green;
}


@media screen and (max-width: 700px) {

  .new-crypto-asset {
    width: 90vw;
    height: 30vh;
    margin-top: 3vh;
    margin-bottom: 0vh;
  }

  .new-crypto-asset img.close-icon {
    position: absolute;
    left: 78.5vw;
    top: 1.5vh;
    width: 4vh;
    height: 4vh;
  }

  .new-crypto-asset span.title {
    display: block;
    font-size: 3.2vh;
    color: rgba(0,0,0,0.8);
    text-align: center;
    height: 3.5vh;
    margin-top: 0vh;
  }

  .new-crypto-asset img.search {
    width: 13vw;
    height: 13vw;
  }

  .new-crypto-asset input {
    width: 80vw;
    margin-left: 5vw;
    height: 7vh;
    padding-left: 2vw;
    padding-right: 2vw;
    border-radius: 1.5vh;
    font-size: 2.5vh;
  }

  #new-asset-submit {
    width: 18vw;
    height: 18vw;
    margin-left: 36vw;
    margin-top: 3vh;
    text-align: center;
    padding-top: 1.2vh;
    cursor: pointer;
    border-radius: 10vh;
  }

  #new-asset-submit:hover {
    background-color: green;
  }

}
