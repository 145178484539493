@import url(https://fonts.googleapis.com/css?family=Orbitron:400,500);
@import url(https://fonts.googleapis.com/css?family=Orbitron:400,500);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

div.App {
  background-color: #F7F7F7;
  min-height: 100vh;
  width: 100vw;
}

::-webkit-scrollbar {
    display: none;
}

div.assets-loading {
    width: 20vw;
    position: absolute;
    margin-left: 40vw;
    margin-top: 43vh;
}

div.assets-loading img.loading {
    width: 20vw;
}

div.assets-loading h2.text {
  font-size: 3vh;
  text-align: center;
  font-weight: 400;
}

@media screen and (max-width: 700px) {
  div.assets-loading {
    width: 60vw;
    margin-left: 30vw;
    margin-top: 77vh;
  }

  div.assets-loading img.loading {
      width: 40vw;
  }

}

div.summary {
  background-color: #1C2238;
  height: 12vh;
  margin-bottom: 3vh;
  width: 100vw;
}

div.summary .label {
  display: block;
  color: rgba(255, 255, 255, 0.5);
  font-size: 2vh;
}

div.summary .value {
  display: block;
  color: white;
  font-size: 3.7vh;
  margin-top: 0.5vh;
}

div.summary .section {
  display: inline-block;
  width: 15vw;
  border-right: solid 2px rgba(255, 255, 255, 0.3);
  margin-right: 3vw;
  margin-top: 2.2vh;
}

div.summary .section:first-of-type {
  margin-left: 15vw;
}

div.summary .button {
    display: inline-block;
    float: right;
    margin-right: 3vw;
    margin-top: 1.9vh;
    cursor: pointer;
}


div.summary .button img {
  height: 5vh;
  width: 5vh;
}

div.summary .button .label {
  color: white;
}

div.summary .section .unit {
  color: white;
  font-size: 3.7vh;
}

div.summary .section input {
  color: white;
  background-color: #1C2238;
  border: none;
  font-size: 3.7vh;
  width: 10vw;
  cursor: pointer;
  opacity: 1;
  padding-left: 0.5vw;
}

#total-invested-span {
  display: inline-block;
  font-size: 3.7vh;
  width: 10vw;
  cursor: pointer;
  opacity: 1;
  padding-left: 0.5vw;
  background-color: #1C2238;
  color: white;
}

#total-invested-span:hover {
  background-color: #414e7f;
  cursor: pointer;
  border-radius: 2vh;
}

#total-invested {
  background-color: #414e7f;
  cursor: pointer;
  border-radius: 2vh;
}

#total-invested:focus {
  background-color: #414e7f;
  border-radius: 2vh;
  outline: none;
}

div.summary .section input::-webkit-input-placeholder {
  color: white;
}

div.summary .section input::-ms-input-placeholder {
  color: white;
}

div.summary .section input::placeholder {
  color: white;
}


@media screen and (max-width: 700px) {

  div.summary {
    height: 40vh;
    padding-bottom: 5vh;
  }

  div.summary .section {
    width: 35vw;
    height: 8vh;
    border-right: none;
    margin-left: 10vw;
    margin-top: 2.2vh;
  }

  div.summary .section:first-of-type {
    margin-left: 10vw;
  }

  div.summary .button {
      float: left;
      margin-right: none;
      margin-top: 4vh;
      margin-left: 42vw;
  }


  div.summary .button img {
    height: 14vw;
    width: 14vw;
  }

  div.summary .button .label {
    color: white;
    text-align: center;
  }

  div.summary .section .unit {
    color: white;
    font-size: 3.7vh;
  }

  div.summary .section input {
    color: white;
    background-color: #1C2238;
    border: none;
    font-size: 3.7vh;
    width: 30vw;
    cursor: pointer;
    opacity: 1;
  }

  div.summary .section input::-webkit-input-placeholder {
    color: white;
  }

  div.summary .section input::-ms-input-placeholder {
    color: white;
  }

  div.summary .section input::placeholder {
    color: white;
  }


}

.navbar {
  display: block;
  background-color: #1C2238;
  width: 100vw;
  height: 9vh;
  color: white;
}

.navbar .logo {
  display: inline-block;
  font-family: "Orbitron";
  font-weight: 500;
  font-size: 4vh;
  padding-top: 2.5vh;
  padding-left: 1vw;
  cursor: pointer;
}

.navbar .element {
  display: inline-block;
  margin-left: 7vw;
  vertical-align: top;
  margin-top: 2.5vh;
}

.navbar .element .link {
  padding-bottom: 1vh;
  cursor: pointer;
}

.navbar .element .link.active {
  border-bottom: solid 1px white;
}

.navbar .element .link:hover {
    border-bottom: solid 2px white;
}

.navbar .logout {
  display: inline-block;
  float: right;
  font-weight: bold;
  font-size: 2.2vh;
  padding-top: 2.2vh;
  width: 10vw;
  height: 8vh;
  background-color: #05D582;
  text-align: center;
  cursor: pointer;
}

.navbar .logout:hover {
  background-color: #05d554;
}


@media screen and (max-width: 700px) {
  .navbar {
    display: block;
    background-color: #1C2238;
    width: 100vw;
    height:auto;
    color: white;
    padding-bottom: 2vh;
  }

  .navbar .logo {
    display: block;
    text-align: center;
    margin-bottom: 3vh;
  }

  .navbar .element {
    display: inline-block;
  }

  .navbar .logout {
    display: block;
    float: none;
    margin-top: 5vh;
    margin-left: 4vw;
    margin-bottom: 2vh;
    font-weight: normal;
    font-size: 2.5vh;
    padding-top: 1.1vh;
    width: 90vw;
    height: 5vh;
    border-radius: 2vh;
    text-align: center;
  }
}

.crypto-asset {
  display: inline-block;
  width: 20vw;
  height: 30vh;
  margin-top: 5vh;
  margin-left: 4vw;
  border-radius: 1vh;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  font-size: 2.5vh;
  line-height: 3vh;
  padding-top: 2vh;
  background-color: white;
  position: relative;
  vertical-align: top;
}

img.close-icon {
  position: absolute;
  left: 18vw;
  top: 1vh;
  width: 3vh;
  height: 3vh;
  float: right;
  margin-right: 2vh;
  opacity: 0.4;
  cursor: pointer;
}

img.close-icon:hover {
  opacity: 0.8;
}

.crypto-asset .logo {
  display: inline-block;
  height:4vw;
  width: 4vw;
  margin-left: 8vw;
  margin-bottom: 1.5vh;
  margin-top: 1vh;
  display: block
}

.crypto-asset span.block {
  display: block;
  text-align: center;
}

.crypto-asset span.asset-name {

}

.crypto-asset span.crypto-balance {
  display: inline-block;
  font-size: 2vh;
  color: rgba(0,0,0,0.8);
  margin-top: 2vh;
  cursor: pointer;
}

.crypto-asset input.crypto-balance {
  display: inline-block;
  width: 6vw;
  margin-left: 4vw;
  font-size: 2vh;
  border: none;
  font-weight: 300;
  cursor: pointer;
  padding-right: 1px;
  text-align: right;
  padding-right: 0.5vw;
  border-radius: 1vh;
}

.crypto-asset input.crypto-balance:hover {
  background-color: #5f6a91;
  color: white;
  font-size: 3vh;
}

.crypto-asset input.crypto-balance:focus {
  box-shadow: none;
  outline: none;
  font-size: 3vh;
  background-color: #414e7f;
  color: white;
}


.crypto-asset span.crypto-balance-span {
  display: inline-block;
  width: 10vw;
  font-size: 2vh;
  border: none;
  font-weight: 300;
  cursor: pointer;
  padding-right: 1px;
  text-align: right;
}

.crypto-asset span.crypto-balance-span:hover {
  color: #0a46a8;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}

.crypto-asset span.crypto-balance.value:hover {
  color: #1689D0;
}

.crypto-asset span.local-currency-value {
  font-size: 3vh;
  color: rgba(0,0,0,0.9);
  margin-top: 1.2vh;
}

@media screen and (max-width: 700px) {

  .crypto-asset {
    width: 90vw;
  }

  img.close-icon {
    position: absolute;
    left: 80vw;
    top: 1vh;
    width: 3vh;
    height: 3vh;
    float: right;
    margin-right: 2vh;
    opacity: 0.4;
    cursor: pointer;
  }

  .crypto-asset .logo {
    height:18vw;
    width: 18vw;
    margin-left: 36vw;
    margin-bottom: 3vh;
    margin-top: 1vh;
  }

  .crypto-asset span.block {
    display: block;
    text-align: center;
  }

  .crypto-asset span.asset-name {
    font-size: 3vh;
    font-weight: 500;
  }

  .crypto-asset span.balance-container {
    text-align: center;
    display: block;

  }

  .crypto-asset input.crypto-balance {
    width: 30vw;
    margin-left: 0vw;
    position: relative;
    right: 3vw;
  }

  .crypto-asset:last-child {
    margin-bottom: 22vh;
  }
}

.new-crypto-asset {
  display: inline-block;
  width: 20vw;
  height: 30vh;
  margin-top: 5vh;
  margin-left: 4vw;
  margin-bottom: 5vh;
  border-radius: 1vh;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  font-size: 2.5vh;
  line-height: 3vh;
  padding-top: 2vh;
  background-color: white;
  position: relative;
}

.new-crypto-asset img.close-icon {
  position: absolute;
  left: 18vw;
  top: 1vh;
  width: 3vh;
  height: 3vh;
  float: right;
  margin-right: 2vh;
  opacity: 0.4;
  cursor: pointer;
}

.new-crypto-asset img.close-icon:hover {
  opacity: 0.8;
}

.new-crypto-asset span.title {
  display: block;
  font-size: 3vh;
  color: black;
  text-align: center;
  height: 4vh;
  margin-top: 2vh;
}

.new-crypto-asset img.search {
  width: 3.5vh;
  height: 3.5vh;
}

.new-crypto-asset input {
  width: 14vw;
  margin-left: 3vw;
  height: 5vh;
  padding-left: 0.2vw;
  padding-right: 0.2vw;
  border-radius: 0.5vh;
  border: solid 1px rgba(0,0,0,0.1);
  margin-top: 3vh;
  font-size: 2vh;
}
.new-crypto-asset input:active {
}

#new-asset-submit {
  width: 3vw;
  height: 3vw;
  color: white;
  background-color: #4FC143;
  margin-left: 8.5vw;
  margin-top: 3vh;
  text-align: center;
  padding-top: 1.2vh;
  cursor: pointer;
  border-radius: 10vh;
}

#new-asset-submit:hover {
  background-color: green;
}


@media screen and (max-width: 700px) {

  .new-crypto-asset {
    width: 90vw;
    height: 30vh;
    margin-top: 3vh;
    margin-bottom: 0vh;
  }

  .new-crypto-asset img.close-icon {
    position: absolute;
    left: 78.5vw;
    top: 1.5vh;
    width: 4vh;
    height: 4vh;
  }

  .new-crypto-asset span.title {
    display: block;
    font-size: 3.2vh;
    color: rgba(0,0,0,0.8);
    text-align: center;
    height: 3.5vh;
    margin-top: 0vh;
  }

  .new-crypto-asset img.search {
    width: 13vw;
    height: 13vw;
  }

  .new-crypto-asset input {
    width: 80vw;
    margin-left: 5vw;
    height: 7vh;
    padding-left: 2vw;
    padding-right: 2vw;
    border-radius: 1.5vh;
    font-size: 2.5vh;
  }

  #new-asset-submit {
    width: 18vw;
    height: 18vw;
    margin-left: 36vw;
    margin-top: 3vh;
    text-align: center;
    padding-top: 1.2vh;
    cursor: pointer;
    border-radius: 10vh;
  }

  #new-asset-submit:hover {
    background-color: green;
  }

}

.settings-modal {
  display: inline-block;
  z-index: 2;
  position: absolute;
  top: 20vh;
  left: 30vw;
  background-color: white;
  width: 40vw;
  height: 65vh;
  border-radius: 2vh;
}

.settings-modal img.close-icon {
  left: 37vw;
  margin-top: 1.5vh;
  width: 4vh;
  height: 4vh;
  float: right;
  margin-right: 2vh;
  opacity: 0.4;
  cursor: pointer;
}

.settings-modal img.close-icon:hover {
  opacity: 0.7;
}

.settings-modal img.profile-placeholder {
  height: 10vw;
  width: 10vw;
  margin-left: 15vw;
  border-radius: 100vh;
  margin-top: 5vh;
  margin-bottom: 4vh;
}



.settings-modal span.input-label {
  display: block;
  text-align: center;
  font-size: 2.3vh;
  margin-bottom: 1vh;
  color: rgba(0,0,0,0.9)
}


.settings-modal input {
  display: block;
  height: 5vh;
  width: 20vw;
  margin-left: 10vw;
  border: solid 1px rgba(0,0,0,0.1);
  border-radius: 1vh;
  text-align: center;
  font-size: 3vh;
  margin-bottom: 2vh;
}

input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}

.settings-modal #settings-modal-currency-select {
  width: 10vw;
  margin-left: 15vw;
  height: 4.5vh;
  font-size: 2.6vh;
}

.settings-modal #settings-modal-save-button {
  width: 10vw;
  height: 6vh;
  background-color: #05D582;
  font-size: 4.5vh;
  border-radius: 1vh;
  text-align: center;
  color: white;
  margin-left: 15vw;
  margin-top: 3vh;
  cursor: pointer;
}

.settings-modal #settings-modal-save-button:hover {
  background-color: #05d554;
}

.settings-modal div.save-button {
  width: 4vw;
  height: 4vw;
  background-color: #4FC143;
  margin-left: 18vw;
  margin-top: 5vh;
  text-align: center;
  padding-top: 1.2vh;
  cursor: pointer;
  border-radius: 10vh;
}

.settings-modal div.save-button:hover {
  background-color: #7edb4c;
}

.settings-modal div.save-button img {
  width: 2.5vw;
  height: 2.5vw;
  margin-top: 0.2vw;
}


@media screen and (max-width: 700px) {

  .settings-modal {
    top: 10vh;
    left: 5vw;
    width: 90vw;
    height: 80vh;
  }

  .settings-modal img.close-icon {
    left: 78vw;
  }

  .settings-modal img.profile-placeholder {
    height: 25vw;
    width: 25vw;
    margin-left: 32.5vw;
  }

  .settings-modal span.input-label {
    font-size: 3vh;
    margin-bottom: 3vh;
    margin-left: 3vw;
    margin-right: 3vw;
  }


  .settings-modal input {
    height: 7vh;
    width: 70vw;
    font-size: 3vh;
    text-align: center;
  }

  .settings-modal  input[type=date]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      display: none;
  }

  .settings-modal div.save-button {
    width: 4vw;
    height: 4vw;
    background-color: #4FC143;
    margin-left: 18vw;
    margin-top: 5vh;
    text-align: center;
    padding-top: 1.2vh;
    cursor: pointer;
    border-radius: 10vh;
  }

  .settings-modal div.save-button:hover {
    background-color: #7edb4c;
  }

  .settings-modal div.save-button img {
    width: 2.5vw;
    height: 2.5vw;
    margin-top: 0.2vw;
  }

  .settings-modal #settings-modal-currency-select {
    width: 50vw;
    margin-left: 20vw;
    height: 6vh;
    font-size: 3vh;
    text-align: center;
    border: solid 1px rgba(0,0,0,0.2)
  }

  .settings-modal #settings-modal-save-button {
    width: 50vw;
    height: 6vh;
    margin-left: 20vw;
    margin-top: 5vh;
  }

}

div.modal-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 1
}

div.auth {
  display: inline-block;
  height: 60vh;
  width: 40vw;
  margin-left: 9vw;
  margin-top: 13vh;
  background-color: white;
  padding-top: 1vh;
  border: solid 1px rgba(0,0,0,0.2);
  border-radius: 2vh;
}

div.auth .title{
  font-weight: 300;
  display: block;
  font-size: 4vh;
  text-align: center;
}

div.auth input {
  display: block;
  width: 20vw;
  height: 5vh;
  margin-left: 10vw;
  margin-top: 2vh;
  border-radius: 2vh;
  border: solid 1px rgba(0,0,0,0.5);
  font-size: 2.5vh;
  padding-left: 1vw;
  padding-right: 1vw;
}

div.auth input:focus {
  box-shadow: none;
  outline: none;
}

div.auth div.error {
  display: block;
  text-align: center;
  width: 20vw;
  margin-left: 10vw;
  border-radius: 1vw;
  background-color: #dd726c;
  font-size: 2.5vh;
  height: 5vh;
  padding-top: 1vh;
  color: rgba(0,0,0,1);
  margin-top: 2vh;

}


div.auth div.submit-button {
  width: 4vw;
  height: 4vw;
  background-color: green;
  border-radius: 10vh;
  margin-top: 4vh;
  margin-left: 18vw;
  cursor: pointer;
}

div.auth div.submit-button:hover {
  background-color: #05D582;
}

div.auth div.submit-button img {
  width: 2.5vw;
  height: 2.5vw;
  margin-left: 0.75vw;
  margin-top: 0.7vw;
}

div.auth span.switch-mode {
  color: black;
  display: block;
  text-align: center;
  font-size: 2vh;
  margin-top: 4vh;
  cursor: pointer;
}

div.auth span.switch-mode:hover {
  color: blue;
  text-decoration: underline;
}

div.info-element {
  display: inline-block;
  width: 37vw;
  height: 60vh;
  margin-left: 6vw;
  margin-top: 13vh;
  vertical-align: top;
}

div.info-element h1 {
  font-weight: 400;
  font-size: 5vh;
  line-height: 7vh;
  margin-top: 0vh;
}

div.info-element h2 {
  font-weight: 400;
  font-size: 3.5vh;
  line-height: 7vh;
  margin-top: 0vh;
}

div.info-element img.screenshot {
  margin-top: 3vh;
  width: 100%;
}

@media screen and (max-width: 700px) {

  div.auth {
    height: 52vh;
    width: 90vw;
    margin-left: 5vw;
    margin-top: 10vh;
    margin-bottom: 4vh;
  }

  div.auth input {
    display: block;
    width: 80vw;
    margin-left: 5vw;
    padding-left: 4vw;
    padding-right: 4vw;
  }

  div.auth div.submit-button {
    width: 18vw;
    height: 18vw;
    background-color: green;
    border-radius: 10vh;
    margin-top: 3vh;
    margin-left: 36vw;
  }

  div.auth div.submit-button img {
    width: 12vw;
    height: 12vw;
    margin-left: 3vw;
    margin-top: 2vw;
  }

  div.auth div.error {
    width: 70vw;
    margin-left: 10vw;
    border-radius: 2vw;
  }

  div.info-element {
    display: block;
    width: 90vw;
    height: auto;
    margin-left: 5vw;
    margin-top: 5vh;
  }

  div.info-element h1 {
    text-align: center;
  }

  div.info-element h2 {
    text-align: center;
  }

  div.info-element img.screenshot {
    margin-top: 3vh;
    width: 100%;
  }
}

.onboarding-modal {
  display: inline-block;
  z-index: 2;
  position: absolute;
  top: 20vh;
  left: 20vw;
  background-color: white;
  width: 60vw;
  height: 65vh;
  border-radius: 2vh;
}

.onboarding-modal img.close-icon {
  left: 57vw;
  margin-top: 1.5vh;
  width: 4vh;
  height: 4vh;
  float: right;
  margin-right: 2vh;
  opacity: 0.4;
  cursor: pointer;
}

.onboarding-modal img.close-icon:hover {
  opacity: 0.7;
}

.onboarding-modal h1 {
  display: block;
  font-size: 3.5vh;
  font-weight: 400;
  text-align: center;
  height: 5vh;
  margin-top: 2.5vh;
  margin-bottom: 2vh;
}

.onboarding-modal .content {
  height: 49.5vh;
  width: 55vw;
  margin-left: 2.5vw;
  margin-top: 3vh;
}

.onboarding-modal .content .text-block {
  display: inline-block;
  width: 25vw;
  vertical-align: top;
}


.onboarding-modal .content .text-block h2 {
  font-size: 3vh;
  margin-bottom: 2.5vh;
  margin-top: 0vh;
}

.onboarding-modal .content .text-block p {
  line-height: 5vh;
  font-size: 2.4vh;
}


.onboarding-modal .content .image-block {
  display: inline-block;
  width: 22vw;
  margin-left: 5vw;
}

.onboarding-modal .content .image-block img.image-1 {
  width: 22vw;
}


.onboarding-modal .button-holder {
  width: 60vw;
  height: 7vh;
  border-bottom-left-radius: 2vh;
  border-bottom-right-radius: 2vh;
}

.onboarding-modal .button-holder .button {
  width: 8vw;
  color: white;
  height: 5vh;
  display: inline-block;
  font-size: 2.7vh;
  line-height: 5vh;
  text-align: center;
  cursor: pointer;
}

.onboarding-modal .button-holder .button.next {
  float: right;
  border-bottom-right-radius: 2vh;
  border-top-left-radius: 2vh;
  background-color: #1C2238;
}

.onboarding-modal .button-holder .button.back {
  float: left;
  border-bottom-left-radius: 2vh;
  border-top-right-radius: 2vh;
  background-color: #939393;
}

@media screen and (max-width: 700px) {

  .onboarding-modal {
    top: 10vh;
    left: 5vw;
    width: 90vw;
    height: 85vh;
  }

  .onboarding-modal img.close-icon {
    left: 79vw;
    margin-top: 0vh;
  }

  .onboarding-modal h1 {
    font-size: 2.8vh;
    height: 3vh;
    margin-top: 3vh;
    margin-bottom: 4vh;
  }

  .onboarding-modal .content {
    height: 68vh;
    width: 85vw;
    margin-left: 2.5vw;
    margin-top: 3vh;
  }

  .onboarding-modal .content .text-block {
    width: 85vw;
  }


  .onboarding-modal .content .text-block h2 {
    font-size: 2.4vh;
    margin-bottom: 1vh;
    margin-top: 0vh;
    font-weight: 500;
  }

  .onboarding-modal .content .text-block p {
    line-height: 3.5vh;
    font-size: 2vh;
  }


  .onboarding-modal .content .image-block {
    display: inline-block;
    width: 60vw;
    margin-left: 12vw;
  }

  .onboarding-modal .content .image-block img.image-1 {
    width: 60vw;
  }


  .onboarding-modal .button-holder {
    width: 90vw;
    height: 20vh;
  }

  .onboarding-modal .button-holder .button {
    width: 23vw;
    height: 7vh;
    padding-top: 1vh;
    display: inline-block;
  }

  .onboarding-modal .button-holder .button.next {
    float: right;
    border-bottom-right-radius: 2vh;
    border-top-left-radius: 2vh;
    background-color: #1C2238;
  }

  .onboarding-modal .button-holder .button.back {
    float: left;
    border-bottom-left-radius: 2vh;
    border-top-right-radius: 2vh;
    background-color: #939393;
  }

}

