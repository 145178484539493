@import url('https://fonts.googleapis.com/css?family=Orbitron:400,500');

div.assets-loading {
    width: 20vw;
    position: absolute;
    margin-left: 40vw;
    margin-top: 43vh;
}

div.assets-loading img.loading {
    width: 20vw;
}

div.assets-loading h2.text {
  font-size: 3vh;
  text-align: center;
  font-weight: 400;
}

@media screen and (max-width: 700px) {
  div.assets-loading {
    width: 60vw;
    margin-left: 30vw;
    margin-top: 77vh;
  }

  div.assets-loading img.loading {
      width: 40vw;
  }

}
