.onboarding-modal {
  display: inline-block;
  z-index: 2;
  position: absolute;
  top: 20vh;
  left: 20vw;
  background-color: white;
  width: 60vw;
  height: 65vh;
  border-radius: 2vh;
}

.onboarding-modal img.close-icon {
  left: 57vw;
  margin-top: 1.5vh;
  width: 4vh;
  height: 4vh;
  float: right;
  margin-right: 2vh;
  opacity: 0.4;
  cursor: pointer;
}

.onboarding-modal img.close-icon:hover {
  opacity: 0.7;
}

.onboarding-modal h1 {
  display: block;
  font-size: 3.5vh;
  font-weight: 400;
  text-align: center;
  height: 5vh;
  margin-top: 2.5vh;
  margin-bottom: 2vh;
}

.onboarding-modal .content {
  height: 49.5vh;
  width: 55vw;
  margin-left: 2.5vw;
  margin-top: 3vh;
}

.onboarding-modal .content .text-block {
  display: inline-block;
  width: 25vw;
  vertical-align: top;
}


.onboarding-modal .content .text-block h2 {
  font-size: 3vh;
  margin-bottom: 2.5vh;
  margin-top: 0vh;
}

.onboarding-modal .content .text-block p {
  line-height: 5vh;
  font-size: 2.4vh;
}


.onboarding-modal .content .image-block {
  display: inline-block;
  width: 22vw;
  margin-left: 5vw;
}

.onboarding-modal .content .image-block img.image-1 {
  width: 22vw;
}


.onboarding-modal .button-holder {
  width: 60vw;
  height: 7vh;
  border-bottom-left-radius: 2vh;
  border-bottom-right-radius: 2vh;
}

.onboarding-modal .button-holder .button {
  width: 8vw;
  color: white;
  height: 5vh;
  display: inline-block;
  font-size: 2.7vh;
  line-height: 5vh;
  text-align: center;
  cursor: pointer;
}

.onboarding-modal .button-holder .button.next {
  float: right;
  border-bottom-right-radius: 2vh;
  border-top-left-radius: 2vh;
  background-color: #1C2238;
}

.onboarding-modal .button-holder .button.back {
  float: left;
  border-bottom-left-radius: 2vh;
  border-top-right-radius: 2vh;
  background-color: #939393;
}

@media screen and (max-width: 700px) {

  .onboarding-modal {
    top: 10vh;
    left: 5vw;
    width: 90vw;
    height: 85vh;
  }

  .onboarding-modal img.close-icon {
    left: 79vw;
    margin-top: 0vh;
  }

  .onboarding-modal h1 {
    font-size: 2.8vh;
    height: 3vh;
    margin-top: 3vh;
    margin-bottom: 4vh;
  }

  .onboarding-modal .content {
    height: 68vh;
    width: 85vw;
    margin-left: 2.5vw;
    margin-top: 3vh;
  }

  .onboarding-modal .content .text-block {
    width: 85vw;
  }


  .onboarding-modal .content .text-block h2 {
    font-size: 2.4vh;
    margin-bottom: 1vh;
    margin-top: 0vh;
    font-weight: 500;
  }

  .onboarding-modal .content .text-block p {
    line-height: 3.5vh;
    font-size: 2vh;
  }


  .onboarding-modal .content .image-block {
    display: inline-block;
    width: 60vw;
    margin-left: 12vw;
  }

  .onboarding-modal .content .image-block img.image-1 {
    width: 60vw;
  }


  .onboarding-modal .button-holder {
    width: 90vw;
    height: 20vh;
  }

  .onboarding-modal .button-holder .button {
    width: 23vw;
    height: 7vh;
    padding-top: 1vh;
    display: inline-block;
  }

  .onboarding-modal .button-holder .button.next {
    float: right;
    border-bottom-right-radius: 2vh;
    border-top-left-radius: 2vh;
    background-color: #1C2238;
  }

  .onboarding-modal .button-holder .button.back {
    float: left;
    border-bottom-left-radius: 2vh;
    border-top-right-radius: 2vh;
    background-color: #939393;
  }

}
