@import url('https://fonts.googleapis.com/css?family=Orbitron:400,500');

.navbar {
  display: block;
  background-color: #1C2238;
  width: 100vw;
  height: 9vh;
  color: white;
}

.navbar .logo {
  display: inline-block;
  font-family: "Orbitron";
  font-weight: 500;
  font-size: 4vh;
  padding-top: 2.5vh;
  padding-left: 1vw;
  cursor: pointer;
}

.navbar .element {
  display: inline-block;
  margin-left: 7vw;
  vertical-align: top;
  margin-top: 2.5vh;
}

.navbar .element .link {
  padding-bottom: 1vh;
  cursor: pointer;
}

.navbar .element .link.active {
  border-bottom: solid 1px white;
}

.navbar .element .link:hover {
    border-bottom: solid 2px white;
}

.navbar .logout {
  display: inline-block;
  float: right;
  font-weight: bold;
  font-size: 2.2vh;
  padding-top: 2.2vh;
  width: 10vw;
  height: 8vh;
  background-color: #05D582;
  text-align: center;
  cursor: pointer;
}

.navbar .logout:hover {
  background-color: #05d554;
}


@media screen and (max-width: 700px) {
  .navbar {
    display: block;
    background-color: #1C2238;
    width: 100vw;
    height:auto;
    color: white;
    padding-bottom: 2vh;
  }

  .navbar .logo {
    display: block;
    text-align: center;
    margin-bottom: 3vh;
  }

  .navbar .element {
    display: inline-block;
  }

  .navbar .logout {
    display: block;
    float: none;
    margin-top: 5vh;
    margin-left: 4vw;
    margin-bottom: 2vh;
    font-weight: normal;
    font-size: 2.5vh;
    padding-top: 1.1vh;
    width: 90vw;
    height: 5vh;
    border-radius: 2vh;
    text-align: center;
  }
}
