div.auth {
  display: inline-block;
  height: 60vh;
  width: 40vw;
  margin-left: 9vw;
  margin-top: 13vh;
  background-color: white;
  padding-top: 1vh;
  border: solid 1px rgba(0,0,0,0.2);
  border-radius: 2vh;
}

div.auth .title{
  font-weight: 300;
  display: block;
  font-size: 4vh;
  text-align: center;
}

div.auth input {
  display: block;
  width: 20vw;
  height: 5vh;
  margin-left: 10vw;
  margin-top: 2vh;
  border-radius: 2vh;
  border: solid 1px rgba(0,0,0,0.5);
  font-size: 2.5vh;
  padding-left: 1vw;
  padding-right: 1vw;
}

div.auth input:focus {
  box-shadow: none;
  outline: none;
}

div.auth div.error {
  display: block;
  text-align: center;
  width: 20vw;
  margin-left: 10vw;
  border-radius: 1vw;
  background-color: #dd726c;
  font-size: 2.5vh;
  height: 5vh;
  padding-top: 1vh;
  color: rgba(0,0,0,1);
  margin-top: 2vh;

}


div.auth div.submit-button {
  width: 4vw;
  height: 4vw;
  background-color: green;
  border-radius: 10vh;
  margin-top: 4vh;
  margin-left: 18vw;
  cursor: pointer;
}

div.auth div.submit-button:hover {
  background-color: #05D582;
}

div.auth div.submit-button img {
  width: 2.5vw;
  height: 2.5vw;
  margin-left: 0.75vw;
  margin-top: 0.7vw;
}

div.auth span.switch-mode {
  color: black;
  display: block;
  text-align: center;
  font-size: 2vh;
  margin-top: 4vh;
  cursor: pointer;
}

div.auth span.switch-mode:hover {
  color: blue;
  text-decoration: underline;
}

div.info-element {
  display: inline-block;
  width: 37vw;
  height: 60vh;
  margin-left: 6vw;
  margin-top: 13vh;
  vertical-align: top;
}

div.info-element h1 {
  font-weight: 400;
  font-size: 5vh;
  line-height: 7vh;
  margin-top: 0vh;
}

div.info-element h2 {
  font-weight: 400;
  font-size: 3.5vh;
  line-height: 7vh;
  margin-top: 0vh;
}

div.info-element img.screenshot {
  margin-top: 3vh;
  width: 100%;
}

@media screen and (max-width: 700px) {

  div.auth {
    height: 52vh;
    width: 90vw;
    margin-left: 5vw;
    margin-top: 10vh;
    margin-bottom: 4vh;
  }

  div.auth input {
    display: block;
    width: 80vw;
    margin-left: 5vw;
    padding-left: 4vw;
    padding-right: 4vw;
  }

  div.auth div.submit-button {
    width: 18vw;
    height: 18vw;
    background-color: green;
    border-radius: 10vh;
    margin-top: 3vh;
    margin-left: 36vw;
  }

  div.auth div.submit-button img {
    width: 12vw;
    height: 12vw;
    margin-left: 3vw;
    margin-top: 2vw;
  }

  div.auth div.error {
    width: 70vw;
    margin-left: 10vw;
    border-radius: 2vw;
  }

  div.info-element {
    display: block;
    width: 90vw;
    height: auto;
    margin-left: 5vw;
    margin-top: 5vh;
  }

  div.info-element h1 {
    text-align: center;
  }

  div.info-element h2 {
    text-align: center;
  }

  div.info-element img.screenshot {
    margin-top: 3vh;
    width: 100%;
  }
}
