* {
  box-sizing: border-box;
}

div.App {
  background-color: #F7F7F7;
  min-height: 100vh;
  width: 100vw;
}

::-webkit-scrollbar {
    display: none;
}
