div.summary {
  background-color: #1C2238;
  height: 12vh;
  margin-bottom: 3vh;
  width: 100vw;
}

div.summary .label {
  display: block;
  color: rgba(255, 255, 255, 0.5);
  font-size: 2vh;
}

div.summary .value {
  display: block;
  color: white;
  font-size: 3.7vh;
  margin-top: 0.5vh;
}

div.summary .section {
  display: inline-block;
  width: 15vw;
  border-right: solid 2px rgba(255, 255, 255, 0.3);
  margin-right: 3vw;
  margin-top: 2.2vh;
}

div.summary .section:first-of-type {
  margin-left: 15vw;
}

div.summary .button {
    display: inline-block;
    float: right;
    margin-right: 3vw;
    margin-top: 1.9vh;
    cursor: pointer;
}


div.summary .button img {
  height: 5vh;
  width: 5vh;
}

div.summary .button .label {
  color: white;
}

div.summary .section .unit {
  color: white;
  font-size: 3.7vh;
}

div.summary .section input {
  color: white;
  background-color: #1C2238;
  border: none;
  font-size: 3.7vh;
  width: 10vw;
  cursor: pointer;
  opacity: 1;
  padding-left: 0.5vw;
}

#total-invested-span {
  display: inline-block;
  font-size: 3.7vh;
  width: 10vw;
  cursor: pointer;
  opacity: 1;
  padding-left: 0.5vw;
  background-color: #1C2238;
  color: white;
}

#total-invested-span:hover {
  background-color: #414e7f;
  cursor: pointer;
  border-radius: 2vh;
}

#total-invested {
  background-color: #414e7f;
  cursor: pointer;
  border-radius: 2vh;
}

#total-invested:focus {
  background-color: #414e7f;
  border-radius: 2vh;
  outline: none;
}

div.summary .section input::placeholder {
  color: white;
}


@media screen and (max-width: 700px) {

  div.summary {
    height: 40vh;
    padding-bottom: 5vh;
  }

  div.summary .section {
    width: 35vw;
    height: 8vh;
    border-right: none;
    margin-left: 10vw;
    margin-top: 2.2vh;
  }

  div.summary .section:first-of-type {
    margin-left: 10vw;
  }

  div.summary .button {
      float: left;
      margin-right: none;
      margin-top: 4vh;
      margin-left: 42vw;
  }


  div.summary .button img {
    height: 14vw;
    width: 14vw;
  }

  div.summary .button .label {
    color: white;
    text-align: center;
  }

  div.summary .section .unit {
    color: white;
    font-size: 3.7vh;
  }

  div.summary .section input {
    color: white;
    background-color: #1C2238;
    border: none;
    font-size: 3.7vh;
    width: 30vw;
    cursor: pointer;
    opacity: 1;
  }

  div.summary .section input::placeholder {
    color: white;
  }


}
