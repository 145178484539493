.settings-modal {
  display: inline-block;
  z-index: 2;
  position: absolute;
  top: 20vh;
  left: 30vw;
  background-color: white;
  width: 40vw;
  height: 65vh;
  border-radius: 2vh;
}

.settings-modal img.close-icon {
  left: 37vw;
  margin-top: 1.5vh;
  width: 4vh;
  height: 4vh;
  float: right;
  margin-right: 2vh;
  opacity: 0.4;
  cursor: pointer;
}

.settings-modal img.close-icon:hover {
  opacity: 0.7;
}

.settings-modal img.profile-placeholder {
  height: 10vw;
  width: 10vw;
  margin-left: 15vw;
  border-radius: 100vh;
  margin-top: 5vh;
  margin-bottom: 4vh;
}



.settings-modal span.input-label {
  display: block;
  text-align: center;
  font-size: 2.3vh;
  margin-bottom: 1vh;
  color: rgba(0,0,0,0.9)
}


.settings-modal input {
  display: block;
  height: 5vh;
  width: 20vw;
  margin-left: 10vw;
  border: solid 1px rgba(0,0,0,0.1);
  border-radius: 1vh;
  text-align: center;
  font-size: 3vh;
  margin-bottom: 2vh;
}

input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}

.settings-modal #settings-modal-currency-select {
  width: 10vw;
  margin-left: 15vw;
  height: 4.5vh;
  font-size: 2.6vh;
}

.settings-modal #settings-modal-save-button {
  width: 10vw;
  height: 6vh;
  background-color: #05D582;
  font-size: 4.5vh;
  border-radius: 1vh;
  text-align: center;
  color: white;
  margin-left: 15vw;
  margin-top: 3vh;
  cursor: pointer;
}

.settings-modal #settings-modal-save-button:hover {
  background-color: #05d554;
}

.settings-modal div.save-button {
  width: 4vw;
  height: 4vw;
  background-color: #4FC143;
  margin-left: 18vw;
  margin-top: 5vh;
  text-align: center;
  padding-top: 1.2vh;
  cursor: pointer;
  border-radius: 10vh;
}

.settings-modal div.save-button:hover {
  background-color: #7edb4c;
}

.settings-modal div.save-button img {
  width: 2.5vw;
  height: 2.5vw;
  margin-top: 0.2vw;
}


@media screen and (max-width: 700px) {

  .settings-modal {
    top: 10vh;
    left: 5vw;
    width: 90vw;
    height: 80vh;
  }

  .settings-modal img.close-icon {
    left: 78vw;
  }

  .settings-modal img.profile-placeholder {
    height: 25vw;
    width: 25vw;
    margin-left: 32.5vw;
  }

  .settings-modal span.input-label {
    font-size: 3vh;
    margin-bottom: 3vh;
    margin-left: 3vw;
    margin-right: 3vw;
  }


  .settings-modal input {
    height: 7vh;
    width: 70vw;
    font-size: 3vh;
    text-align: center;
  }

  .settings-modal  input[type=date]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      display: none;
  }

  .settings-modal div.save-button {
    width: 4vw;
    height: 4vw;
    background-color: #4FC143;
    margin-left: 18vw;
    margin-top: 5vh;
    text-align: center;
    padding-top: 1.2vh;
    cursor: pointer;
    border-radius: 10vh;
  }

  .settings-modal div.save-button:hover {
    background-color: #7edb4c;
  }

  .settings-modal div.save-button img {
    width: 2.5vw;
    height: 2.5vw;
    margin-top: 0.2vw;
  }

  .settings-modal #settings-modal-currency-select {
    width: 50vw;
    margin-left: 20vw;
    height: 6vh;
    font-size: 3vh;
    text-align: center;
    border: solid 1px rgba(0,0,0,0.2)
  }

  .settings-modal #settings-modal-save-button {
    width: 50vw;
    height: 6vh;
    margin-left: 20vw;
    margin-top: 5vh;
  }

}
